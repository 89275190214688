<template>
  <!-- 상태 변경 팝업 -->
  <transition name="modal" appear>
    <div class="modal modal-overlay" v-if="onRegisterModal" @click.self="handleWrapperClick">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="width:1200px">
          <p class="bold dp-inblock h-30px font-23">예약 하기</p>
          <div class="con">
            <div class="con_table mt-20">
              <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00 w-100per">
                <tbody>
                  <tr>
                    <th>고객명 <span class="red">*</span></th>
                    <td class="left pl-20">
                      <input v-if="!isInputDisabled && (auth === 'MASTER' || auth === 'ADMIN')" type="radio" id="new" value="new" v-model="customerType" @click="isInputDisabled ? null : radio()" />
                      <label for="new" v-if="!isInputDisabled && (auth === 'MASTER' || auth === 'ADMIN')">신규고객</label>
                      <input :class="auth === 'MASTER' || auth === 'ADMIN' ? 'ml-30' : 'ml-10'" type="radio" id="old" value="old" v-model="customerType" @click="isInputDisabled ? null : radio()" />
                      <label for="old">기존고객(기존차트)</label> <input v-if="auth === 'MASTER' || auth === 'ADMIN'" class="ml-30" type="radio" id="oldNew" value="oldNew" v-model="customerType" @click="isInputDisabled ? null : radio()" />
                      <label v-if="auth === 'MASTER' || auth === 'ADMIN'" for="oldNew">기존고객(새차트)</label><br />
                      <div v-if="customerType === 'old'" class="app" v-on:keyup.down="selectValue('down')" v-on:keyup.up="selectValue('up')">
                        <div class="search">
                          <input type="text" v-model="name" :disabled="isInputDisabled" class="" style="width:400px;" id="name" name="name" @input="watchEvent($event, 'client')" />
                          <ul class="c" tabindex="0" v-bind:class="{ show: isAutoClient }" v-on:mouseover="removeValue">
                            <li tabindex="-1" v-for="(el, index) in filterClientList" v-on:click="changeValue(el.name, 'client', el.idx, el.idxCrmPerson)" v-on:keyup.enter="selectValue('enter', el.name)" :key="index">
                              <span>{{ el.nickName }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div v-if="customerType === 'oldNew'" class="app" v-on:keyup.down="selectValue('down')" v-on:keyup.up="selectValue('up')">
                        <div class="search">
                          <input type="text" v-model="name" :disabled="isInputDisabled" class="" style="width:400px;" id="name" name="name" @input="watchEvent($event, 'person')" />
                          <ul class="c" tabindex="0" v-bind:class="{ show: isAutoPerson }" v-on:mouseover="removeValue">
                            <li tabindex="-1" v-for="(el, index) in filterPersonList" v-on:click="changeValue(el.name, 'person', -1, el.idx)" v-on:keyup.enter="selectValue('enter', el.name)" :key="index">
                              <span>{{ el.name }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div v-if="customerType === 'new'">
                        <input v-model="name" type="text" class="ml-10" style="width:400px;" value="" />
                      </div>
                    </td>
                    <th>성별 <span class="red">*</span></th>
                    <td class="left pl-30">
                      <select v-model="gender" class="w-20px" :disabled="customerType === 'old' || customerType === 'oldNew'">
                        <option value="">성별</option>
                        <option value="남자">남</option>
                        <option value="여자">여</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>생년월일</th>
                    <td class="left pl-30">
                      <crm-datepicker v-if="customerType === 'new'" v-model="birth" :parentDate="birth" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                      <input v-if="customerType === 'old' || customerType === 'oldNew'" type="text" v-model="birth" disabled />
                    </td>
                    <th>연령선택 <span class="red">*</span></th>
                    <td class="left pl-30">
                      <select class="w-120px" v-model="ageRange" :disabled="customerType === 'old' || customerType === 'oldNew'">
                        <option value="">연령선택</option>
                        <option value="10대 이하">10대 이하</option>
                        <option value="20대">20대</option>
                        <option value="30대">30대</option>
                        <option value="40대">40대</option>
                        <option value="50대이상">50대이상</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>이메일</th>
                    <td class="left pl-30" :colspan="auth == 'STAFF' ? 3 : 1">
                      <input :disabled="customerType === 'old' || customerType === 'oldNew'" v-model="email" type="text" class="w-200px" value="" />
                    </td>
                    <th v-if="auth != 'STAFF'">소속</th>
                    <td class="left" v-if="auth != 'STAFF'">
                      <div class="app" v-on:keyup.down="selectValueCompany('down')" v-on:keyup.up="selectValueCompany('up')">
                        <div class="search">
                          <input type="text" v-model="companyName" class="ml-05" style="width:200px;" id="company" name="company" @input="watchEvent($event, 'company')" />
                          <ul class="r" tabindex="0" v-bind:class="{ show: isAutoCompany }" v-on:mouseover="removeValue">
                            <li tabindex="-1" v-for="(el, index) in filterCompanyList" v-on:click="changeValue(el.name, 'company', el.idx)" v-on:keyup.enter="selectValue('enter', el.name, el.idx)" :key="index">
                              <span>{{ el.name }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>휴대폰 번호 <span class="red">*</span></th>
                    <td class="left pl-10">
                      <select :disabled="customerType === 'old' || customerType === 'oldNew'" class="ml-20" v-model="phone1" id="phone1" name="phone1">
                        <option value="010">010</option>
                        <option value="011">011</option>
                        <option value="016">016</option>
                        <option value="017">017</option>
                        <option value="018">018</option>
                        <option value="019">019</option>
                      </select>
                      - <input :disabled="customerType === 'old' || customerType === 'oldNew'" type="text" v-model="phone2" style="width:100px;" id="phone2" name="phone2" value="" /> -
                      <input :disabled="customerType === 'old' || customerType === 'oldNew'" type="text" v-model="phone3" style="width:100px;" id="phone3" name="phone3" value="" />
                      <button v-if="customerType === 'new'" type="button" class="default-btn ml-20" @click="checkPhoneVaild">중복확인</button>
                    </td>
                    <th>유입경로</th>
                    <td class="left pl-30">
                      <select :disabled="customerType === 'old' || customerType === 'oldNew'" v-model="idxCrmMetaRoute" class="w-120px">
                        <option value="-1">유입경로DB</option>
                        <option v-for="(item, index) of routeList" :key="index" :value="item.idx">{{ item.type }}</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th class="bold w-100px">예약일자</th>
                    <td class="left pl-20">
                      <crm-datepicker class="ml-10" @input="getStaffSchedule()" v-model="consDate" :parentDate="consDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                      <!-- <button type="button" class="default-btn ml-20" @click="getStaffSchedule()">상담사 스케쥴보기</button> -->
                    </td>
                    <th style="width: 111px;">예약현황 <span class="red">*</span></th>
                    <td class="left pl-30">
                      <select v-model="reservationStatus" class="w-120px">
                        <option value="">예약현황선택</option>
                        <option value="예약">예약</option>
                        <option value="당일취소">취소</option>
                        <option value="NS">NS</option>
                        <option value="입실">입실</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th class="bold w-100px">예약시간 <span class="red">*</span></th>
                    <td class="left" colspan="3">
                      <table class="default_table w-1000px mb-20 mt-20 ml-20">
                        <tbody>
                          <tr>
                            <th>09:00</th>
                            <th>09:30</th>
                            <th>10:00</th>
                            <th>10:30</th>
                            <th>11:00</th>
                            <th>11:30</th>
                            <th>12:00</th>
                            <th>12:30</th>
                            <th>13:00</th>
                            <th>13:30</th>
                            <th>14:00</th>
                            <th>14:30</th>
                            <th>15:00</th>
                            <th>15:30</th>
                            <th>-</th>
                          </tr>
                          <tr>
                            <td v-for="(item, idx) of consTimeList.slice(0, 15)" :key="idx"><input type="checkbox" v-model="item.use" :disabled="item.can === false" /></td>
                          </tr>
                          <tr>
                            <th>16:00</th>
                            <th>16:30</th>
                            <th>17:00</th>
                            <th>17:30</th>
                            <th>18:00</th>
                            <th>18:30</th>
                            <th>19:00</th>
                            <th>19:30</th>
                            <th>20:00</th>
                            <th>20:30</th>
                            <th>21:00</th>
                            <th>21:30</th>
                            <th>22:00</th>
                            <th>22:30</th>
                            <th>23:00</th>
                          </tr>
                          <tr>
                            <td v-for="(item, idx) of consTimeList.slice(15, 30)" :key="idx"><input type="checkbox" v-model="item.use" :disabled="item.can === false" /></td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- <input type="text" class="ml-30 w-1000px" value=""> -->
                    </td>
                  </tr>
                  <tr v-if="customerType === 'new' || customerType === 'oldNew'">
                    <th class="bold">센터선택 <span class="red">*</span></th>
                    <td class="left pl-20" colspan="3">
                      <select v-if="auth !== 'MASTER' && auth !== 'ADMIN'" :disabled="true" v-model="idxCrmCenter" @change="getStaffList()" class="ml-10 w-200px h-30px">
                        <option value="">센터선택</option>
                        <option value="0">본원센터</option>
                        <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{ item.name }}</option>
                      </select>
                      <select v-else :disabled="false" v-model="idxCrmCenter" @change="getStaffList()" class="ml-10 w-200px h-30px">
                        <option value="">센터선택</option>
                        <option value="0">본원센터</option>
                        <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{ item.name }}</option>
                      </select>
                    </td>
                  </tr>
                  <!-- 상담사는 필요없는 부분이다. 상담사, 상담회기 -->
                  <tr v-if="auth !== 'STAFF'">
                    <th class="bold">상담사</th>
                    <td class="left pl-20">
                      <div class="app" v-on:keyup.down="selectValue('down')" v-on:keyup.up="selectValue('up')">
                        <div class="search">
                          <input :disabled="customerType === 'old'" type="text" v-model="staffName" class="ml-10" style="width:200px;" id="counselor" name="counselor" @input="watchEvent($event, 'counselor')" />
                          <ul class="r" tabindex="0" v-bind:class="{ show: isAutoCounselor }" v-on:mouseover="removeValue">
                            <li tabindex="-1" v-for="(el, index) in filterCounselorList" v-on:click="changeValue(el.name, 'counselor', el.idx, -1, el.idx_crm_center, el.center_name)" v-on:keyup.enter="selectValue('enter', el.name, el.idx, -1, el.idx_crm_center, el.center_name)" :key="index">
                              <span>{{ el.name }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <th>상담회기</th>
                    <td class="left pl-30">
                      <input :disabled="customerType === 'old'" v-model="consCount" type="text" class="w-50px" value="" />
                    </td>
                  </tr>
                  <!-- 상담사는 필요없는 부분이다. 상담유형, 진행상황 -->
                  <tr v-if="auth != 'STAFF'">
                    <th>상담유형</th>
                    <td class="left pl-30">
                      <select v-model="idxCrmMetaType" class="w-200px" :disabled="customerType === 'old'">
                        <option value="-1">상담유형선택</option>
                        <option v-for="(item, index) of typeList" :key="index" :value="item.idx">{{ item.type }}</option>
                      </select>
                    </td>
                    <th>진행상황 <span class="red">*</span></th>
                    <td class="left pl-30">
                      <select v-model="clientStatus" class="w-120px">
                        <option value="">진행상황</option>
                        <option value="진행">진행</option>
                        <option value="종결">종결</option>
                        <option value="홀딩">홀딩</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <!-- 상담사는 필요없는 부분이다. - 상담구분 -->
                    <th v-if="auth != 'STAFF'">상담구분</th>
                    <td class="left pl-30" v-if="auth != 'STAFF'">
                      <select v-model="gubun" @change="getProductList()" class="w-120px">
                        <option value="구분">구분</option>
                        <option v-for="(item, index) of gubunList" :key="index" :value="item.type">{{ item.type }}</option>
                      </select>
                      <select v-model="idxCrmMetaProduct" class="w-150px ml-10">
                        <option value="-1">상품명</option>
                        <option v-for="(item, index) of productList" :key="index" :value="item.idx">{{ item.product }} ({{ item.price }}원)</option>
                      </select>

                      <span class="left pl-20">기타권 금액</span>
                      <span class="left pl-20"><input type="text" v-model="etcPrice" class="w-60px" /> 원</span>
                    </td>
                    <th>상담 형태 <span class="red">*</span></th>
                    <td class="left pl-30" :colspan="auth == 'STAFF' ? 3 : 1">
                      <select class="w-200px" v-model="idxCrmMetaContact">
                        <option value="">상담 형태 선택</option>
                        <option v-for="(item, index) in contactList" :key="index" :value="item.idx">
                          {{ item.type }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 111px;">메모</th>
                    <td class="left pl-20" colspan="3">
                      <textarea type="text" v-model="memo" class="ml-10 w-98per h-100px" id="introduce" name="introduce" value="" />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="btns3 mgB80">
                <a class="btn_sms mr-10 pointer" @click="changeModalStatus()">닫기</a>
                <a class="btn_cancle mr-10 pointer" @click="register()">등록</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { EmptyCheck } from "@/utils/validation.js";
import store from "@/store/index";
export default {
  props: {
    onRegisterModal: {
      type: Boolean,
      require: true,
      default: false,
    },

    consStartDate: {
      type: String,
      require: true,
    },

    idxCrmStaff: {
      type: Number,
      require: true,
    },

    staffName: {
      type: String,
      require: false,
    },

    time: {
      type: String,
      require: false,
    },
    onIdxCrmCenter: {
      type: Number,
      require: false,
    },
    userPersonIdx: {
      type: [Number, String],
      require: false,
    },
    //고객 타입(신규고객 : '', 기존고객(기존차트) old: , 기존고객(새차트) : oldNew)
    cstpye: {
      type: String,
      require: false,
    },
    onIdxCrmClient: {
      type: Number,
      require: false,
    },
  },

  data: () => ({
    consTimeList: [],
    name: "",
    consCount: 0,
    consDate: "",
    consTime: "",
    customerType: "new",
    gender: "남",
    birth: "",
    email: "",
    phone1: "010", // 휴대폰 앞자리
    phone2: "", // 휴대폰 가운데자리
    phone3: "", // 휴대폰 마지막자리
    routeList: [],
    idxCrmMetaRoute: -1,
    isAutoCounselor: false, // 상담사 자동완성 on/off
    autoSearchCounselor: "", // 상담사 자동완성 검색
    counselorList: [], // 상담사 리스트 (자동완성)
    isAutoClient: false, // 유저 자동완성 on/off
    autoSearchClient: "", // 유저 자동완성 검색
    clientList: [], // 유저 리스트 (자동완성)
    isAutoPerson: false, // 유저 자동완성 on/off
    autoSearchPerson: "", // 유저 자동완성 검색
    personList: [], // 유저 리스트 (자동완성)

    companyName: "",
    companyList: [],
    isAutoCompany: false, // 소속(컴퍼니) 자동완성 on/off
    autoSearchCompany: "", // 소속(컴퍼니) 자동완성 검색
    idxCrmClient: -1,
    workTimeList: [],
    typeList: [],
    idxCrmMetaType: -1,
    clientStatus: "",
    reservationStatus: "",
    eapYn: "N",
    idxCrmPerson: -1,
    memo: "",
    btnDisabled: false, //등록버튼 활성화/비활성화 여부
    isPhoneVaild: true,
    numberRegExp: /^[0-9]{3,}$/ /* 숫자 정규식 */,
    ageRange: "" /* 연령선택 */,
    conTypeList: [],

    gubunList: [],
    productList: [], //상품리스트
    couTypeList: [], //상담형태 리스트

    gubun: "" || "구분", //상담구분
    idxCrmMetaProduct: -1, //상담구분 하위
    etcPrice: 0, //기타권금액

    idxCrmMetaContact: "", //상담형태

    contactList: [], //상담형태 리스트
    auth: store.state.userInfo.auth,
    centerList: [], //센터리스트
    idxCrmCenter: -1,
    isMaster: false,
    isAdmin: false,
    staffTime: "",
    isInputDisabled: false,
  }),

  created() {
    if (store.state.userInfo.auth === "MASTER") {
      this.isMaster = true;
    } else {
      this.isMaster = false;
    }

    if (store.state.userInfo.auth === "ADMIN") {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  },

  computed: {
    filterCounselorList() {
      const str = this.autoSearchCounselor;
      const reg = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9|\s]/.test(str);
      if (reg === false && str !== "" && str !== " ") {
        this.isAutoCounselor = true;
        var filterNames = [];
        filterNames = this.counselorList.filter((el) => {
          return el.name.match(str);
        });
        if (filterNames.length > 0) {
          return filterNames;
        } else {
          this.isAutoCounselor = false;
        }
      } else {
        this.isAutoCounselor = false;
      }

      return false;
    },

    filterClientList() {
      const str = this.autoSearchClient;
      const reg = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9|\s]/.test(str);
      if (reg === false && str !== "" && str !== " ") {
        this.isAutoClient = true;
        var filterNames = [];
        filterNames = this.clientList.filter((el) => {
          return el.name.match(str);
        });
        if (filterNames.length > 0) {
          return filterNames;
        } else {
          this.isAutoClient = false;
        }
      } else {
        this.isAutoClient = false;
      }

      return false;
    },

    filterPersonList() {
      const str = this.autoSearchPerson;
      const reg = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9|\s]/.test(str);
      if (reg === false && str !== "" && str !== " ") {
        this.isAutoPerson = true;
        var filterNames = [];
        filterNames = this.personList.filter((el) => {
          return el.name.match(str);
        });
        if (filterNames.length > 0) {
          return filterNames;
        } else {
          this.isAutoPerson = false;
        }
      } else {
        this.isAutoPerson = false;
      }

      return false;
    },

    filterCompanyList() {
      const str = this.autoSearchCompany;
      const reg = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9|\s]/.test(str);
      if (reg === false && str !== "" && str !== " ") {
        this.isAutoCompany = true;
        var filterNames = [];
        filterNames = this.companyList.filter((el) => {
          return el.name.match(str);
        });
        if (filterNames.length > 0) {
          return filterNames;
        } else {
          this.isAutoCompany = false;
        }
      } else {
        this.isAutoCompany = false;
      }
    },
  },

  watch: {
    userPersonIdx(val) {
      this.customerType = "oldNew";
      this.getPersonData(val);
    },

    consStartDate(val) {
      this.consDate = this.formatDate(val);
    },
    phone1() {
      if (this.customerType === "new") {
        this.isPhoneVaild = false;
      }
    },
    phone2() {
      if (this.customerType === "new") {
        this.isPhoneVaild = false;
      }
    },
    phone3() {
      if (this.customerType === "new") {
        this.isPhoneVaild = false;
      }
    },
    customerType(val) {
      if (val !== "new" && !this.isInputDisabled) {
        this.phone1 = "010";
        this.phone2 = "";
        this.phone3 = "";
      }
    },
    onRegisterModal() {
      this.idxCrmMetaContact = "";
      this.idxCrmCenter = this.onIdxCrmCenter;
      this.customerType = this.cstpye || "new";

      if (this.cstpye == "old") {
        this.idxCrmClient = this.onIdxCrmClient;
        this.getClientData(this.onIdxCrmClient);
      }
    },
  },
  created() {
    this.staffTime = this.time;
  },
  mounted() {
    this.consDate = this.formatDate(this.consStartDate);
    this.birth = this.formatDate(new Date());
    this.getCounselorList();
    this.getMetaList();
    this.setConsTime();
    this.getConTypeMeta();
    this.getGubunList();
    this.getCenterListAll();
    this.getCompanyListAll();
  },

  methods: {
    radio() {
      this.autoSearchClient = "";
      this.autoSearchPerson = "";
      this.customerType = this.auth === "MASTER" || this.auth === "ADMIN" ? "new" : "old";
      this.name = "";
      this.gender = "";
      this.phone1 = "010";
      this.phone2 = "";
      this.phone3 = "";
      this.birth = this.formatDate(new Date());
      this.idxCrmMetaRoute = -1;
      this.idxCrmMetaType = -1;
      this.email = "";
      this.consCount = 0;
    },

    handleWrapperClick() {
      this.customerType = "new";
      this.$emit("update:onRegisterModal", false);
    },

    register() {
      if (this.customerType === "new") {
        this.postPersonInfo();
      } else if (this.customerType === "oldNew") {
        this.postClientInfo();
      } else {
        this.postReservationInfo();
      }
    },
    // 3원본
    postReservationInfo() {
      if (!EmptyCheck(this.reservationStatus, "예약현황을")) {
        return false;
      }

      if (!EmptyCheck(this.clientStatus, "진행상황을")) {
        return false;
      }

      if (!EmptyCheck(this.idxCrmMetaContact.toString(), "상담 형태를")) {
        return false;
      }

      // 근무요일 테이블 파라미터형태로 변경
      let consTimeList = [];
      let copyConsTimeList = JSON.parse(JSON.stringify(this.consTimeList));
      for (var item of copyConsTimeList) {
        if (item.use && item.can) {
          delete item["use"];
          consTimeList.push(item);
        }
      }

      this.consTime = "";
      consTimeList.reverse();
      for (var time of consTimeList) {
        this.consTime = time.time + "/" + this.consTime;
      }

      var params = {
        consTime: this.consTime,
        consDate: this.formatDate(this.consDate),
        name: this.name,
        idxCrmStaff: this.idxCrmStaff,
        idxCrmClient: this.idxCrmClient,
        reservationStatus: this.reservationStatus,
        idxCrmMetaContact: this.idxCrmMetaContact,
        idxCrmMetaProduct: this.idxCrmMetaProduct,
        idxCrmCenter: this.idxCrmCenter,
      };
      if (this.staffName === "") {
        if (this.auth === "STAFF") {
          params.idxCrmStaff = this.idxCrmStaff;
        } else {
          params.idxCrmStaff = null;
        }
      }

      this.btnDisabled = true;
      if ( params.idxCrmClient === -1){
        alert("과정을 다시 시도해주세요")
      }else{
        this.axios
          .post("/api/v1/cons", params)
          .then((res) => {
            if (res.data.err === 0) {
              if (this.memo !== "") {
                this.postMemo();
              }
              alert("등록되었습니다.");
              this.changeModalStatus();
            } else {
              alert(res.data.result);
            }
          })
          .catch((err) => {
            console.error(err);
            if (err.response.data.path === "/login/msg") {
              alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
              this.$router.push("/login");
            } else {
              alert("관리자에게 문의하세요");
            }
          })
          .finally(() => {
            this.btnDisabled = false;
          });
      }
   },
    // 4444
    postMemo() {
      var vo = {
        memo: this.memo,
        idxCrmPerson: this.idxCrmPerson,
      };

      this.axios
        .post("/api/v1/client/memo", vo)
        .then((res) => {
          if (res.data.err === 0) {
            //alert('메모 등록이 완료되었습니다.')
            //this.$router.push('/origin/customer_manage')
            //this.$router.go(this.$router.currentRoute)
            this.changeModalStatus();
          } else {
            //alert(res.data.result)
          }
        })
        .catch((err) => {
          console.error(err.response);
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        });
    },
    // 2222
    postClientInfo() {
      let flag = false;

      // 고객 자동완성 체크 부분
      if (!EmptyCheck(this.name, "고객을")) {
        return false;
      } else {
        for (var person of this.personList) {
          if (person.name === this.name && person.idx === Number(this.idxCrmPerson)) {
            flag = true;
            break;
          }
        }
      }

      if (!this.gender) {
        alert("성별 선택해주세요");
        return false;
      }

      if (!this.ageRange) {
        alert("연령선택 선택해주세요");
        return false;
      }

      if (this.customerType !== "new") {
        if (!flag) {
          alert("고객명을 다시 확인해주세요");
          return false;
        }

        if (this.idxCrmPerson === -1) {
          alert("존재하지 않는 고객입니다.");
          return false;
        }
      }

      if (!EmptyCheck(this.reservationStatus, "예약현황을")) {
        return false;
      }

      // 근무요일 테이블 파라미터형태로 변경
      let consTimeList = [];
      let copyConsTimeList = JSON.parse(JSON.stringify(this.consTimeList));
      for (var item of copyConsTimeList) {
        if (item.use) {
          delete item["use"];
          consTimeList.push(item);
        }
      }
      this.consTime = "";
      consTimeList.reverse();
      for (var time of consTimeList) {
        this.consTime = time.time + "/" + this.consTime;
      }

      if (!EmptyCheck(this.consTime, "상담시간을")) {
        return false;
      }

      if (this.customerType === "new" || this.customerType === "oldNew") {
        if (!EmptyCheck(String(this.idxCrmCenter), "센터를")) {
          return false;
        }
      }

      if (!EmptyCheck(this.clientStatus, "진행상황을")) {
        return false;
      }

      if (!EmptyCheck(this.idxCrmMetaContact.toString(), "상담 형태를")) {
        return false;
      }

      // 핸드폰 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
      if (this.phone2 !== "" && this.phone3 !== "") {
        var phone = this.phone1 + "-" + this.phone2 + "-" + this.phone3;
      } else {
        var phone = "";
      }

      if (phone === "") {
        alert("휴대폰 번호를 입력해주세요.");
        return false;
      }

      var vo = {
        name: this.name,
        gender: this.gender,
        birth: this.formatDate(this.birth),
        email: this.email,
        idxCrmCompany: this.idxCrmCompany,
        idxCrmMetaRoute: this.idxCrmMetaRoute,
        idxCrmCenter: this.idxCrmCenter,
        idxCrmStaff: this.idxCrmStaff,
        idxCrmClient: this.idxCrmClient,
        etcPrice: this.etcPrice,
        idxCrmMetaType: this.idxCrmMetaType,
        consCount: this.consCount,
        clientStatus: this.clientStatus,
        eapYn: this.eapYn,
        idxCrmPerson: this.idxCrmPerson,
        phone: phone,
        idxCrmMetaProduct: this.idxCrmMetaProduct,
        memo: this.memo,
      };
      if (this.btnDisabled) {
        alert("저장중입니다. 잠시만 기다려주세요.");
        return false;
      }

      this.btnDisabled = true;

      this.axios
        .post("/api/v1/client", vo)
        .then((res) => {
          this.btnDisabled = false;
          if (res.data.err === 0) {
            this.idxCrmClient = res.data.idxCrmClient;
            alert("등록되었습니다.");
            this.postReservationInfo();
          } else {
            alert(res.data.result);
          }
        })
        .catch((err) => {
          console.error(err.response);
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else if (err !== 0) {
            alert("관리자에게 문의하세요");
          }
        })
        .finally(() => {
          this.btnDisabled = false;
        });
    },

    // 고객 전체목록 호출
    getPersonList() {
      this.axios
        .get("/api/v1/client/person/list", {})
        .then((res) => {
          if (res.data.err === 0) {
            this.personList = res.data.personList;
          } else {
            this.personList = [];
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.response.data.path === "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        });
    },

    getClientList(idxCrmStaff) {
      this.autoSearchClient = ""; // 닫기하고 다시 들어왔을 때 자동완성 네모 안뜨도록 20210317
      let params = {
        idxCrmStaff: this.idxCrmStaff,
      };
      this.axios
        .get("/api/v1/client/part", params)
        .then((res) => {
          if (res.data.err === 0) {
            this.clientList = res.data.client;
          } else {
            alert(res.data.result);
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.response.data.path === "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        });
    },

    getClientData(idx) {
      //기존고객인 경우의 차트 정보를 가져온다.
      var params = {
        idx: idx,
      };
      this.axios.get(`/api/v1/client/${idx}`, {}).then((res) => {
        if (res.data.err === 0) {
          this.consCount = res.data.clientVO.consCount;
          this.idxCrmMetaType = res.data.clientVO.idxCrmMetaType;
          this.clientStatus = res.data.clientVO.clientStatus;
          this.gubun = res.data.clientVO.gubun;
          this.idxCrmMetaProduct = res.data.clientVO.idxCrmMetaProduct;
          this.companyName = res.data.clientVO.companyName;
          this.idxCrmCompany = res.data.clientVO.idxCrmCompany;
          this.etcPrice = res.data.clientVO.etcPrice;
          this.idxCrmClient = res.data.clientVO.idx;
        }
      });
    },

    getPersonData(idx) {
      //기존고객인 경우의 고객 정보를 가져온다.
      if (idx) {
        this.axios
          .get(`/api/v1/client/person/${idx}`, {})
          .then((res) => {
            if (res.data.err === 0) {
              // 휴대폰 번호 매핑
              var phone = res.data.personVO.phone.split("-");
              if (phone.length === 3) {
                this.phone1 = phone[0];
                this.phone2 = phone[1];
                this.phone3 = phone[2];
              }
              this.isInputDisabled = true; 
              this.idxCrmPerson = res.data.personVO.idx;
              this.gender = res.data.personVO.gender;
              this.birth = res.data.personVO.birth;
              this.email = res.data.personVO.email;
              this.idxCrmMetaRoute = res.data.personVO.idxCrmMetaRoute;
              this.ageRange = res.data.personVO.ageRange;
              this.name = res.data.personVO.name;
              this.idxCrmClient = res.data.personVO.idxCrmClient;
            }
          })
          .catch((err) => {
            console.error(err.response);
            if (err.response.data.path == "/login/msg") {
              alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
              this.$router.push("/login");
            } else {
              alert("관리자에게 문의하세요");
            }
          });
      }
    },

    // 소속리스트(기관리스트)
    getCompanyListAll() {
      this.axios
        .get("/api/v1/company/all", {})
        .then((res) => {
          if (res.data.err === 0) {
            this.companyList = res.data.companyList;
          } else {
            //alert('소속리스트 결과가 없습니다.')
          }
        })
        .catch((err) => {
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        });
    },

    getStaffSchedule() {
      this.staffTime = "";
      let flag = false;

      // 상담사 자동완성 체크 부분
      for (var staff of this.counselorList) {
        staff.idx === Number(this.idxCrmStaff);
        flag = true;
        break;
      }
      if (!flag) {
        staff.idx = Number(this.idxCrmStaff);
        flag = true;
        alert(flag, staff.idx);
        alert("상담사를 다시 확인해주세요");
        alert(this.idxCrmStaff);
        return false;
      }

      if (this.idxCrmStaff === -1) {
        alert("존재하지 않는 상담사입니다.");
        return false;
      }
      var params = {
        idxCrmStaff: this.idxCrmStaff,
        consDate: this.formatDate(this.consDate),
      };
      this.axios
        .get(`/api/v1/user/staff/schedule`, {
          params: params,
        })
        .then((res) => {
          if (res.data.err === 0) {
            this.isValidCheck = true;
            this.schedule = res.data.schedule;
            this.setConsTime();
            if (res.data.schedule.length > 0) {
              for (var i = 0; i < res.data.schedule.length; i++) {
                // 근무요일 테이블 매핑 (자기 자신거)
                if (res.data.schedule[i].idx !== Number(this.idx)) {
                  this.workTimeList = res.data.schedule[i].consTime.split("/");
                  for (var consTime of this.consTimeList) {
                    // 상담사 일하는 시간 외에는 선택하지 못하도록 추가 20210118
                    if (res.data.workTime.work_time.indexOf(consTime.time) === -1) {
                      consTime.can = false;
                    }
                    for (var work of this.workTimeList) {
                      if (work === consTime.time) {
                        consTime.can = false; // false 사용X true 사용O
                        consTime.use = false;
                      }
                    }
                  }
                }
              }
            } else {
              if (res.data.workTime !== null && res.data.workTime !== undefined && res.data.workTime !== "null") {
                for (var consTime of this.consTimeList) {
                  if (res.data.workTime.work_time.indexOf(consTime.time) === -1) {
                    consTime.can = false;
                  }
                  if (this.staffTime === consTime.time) {
                    consTime.can = true;
                    consTime.use = true;
                  }
                }
              } else {
                for (var consTime of this.consTimeList) {
                  consTime.can = false;
                }
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.response.data.path === "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        });
    },

    // 상담사 전체목록 호출
    getCounselorList() {
      let params = {
        idxCrmCenter: -1, // 전원
      };
      this.axios
        .get("/api/v2/user/listall", {
          params: params,
        })
        .then((res) => {
          if (res.data.err === 0) {
            // this.counselorList = res.data.userList;
          } else {
            // alert(res.data.result)
            this.counselorList = [];
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.response.data.path === "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        });
    },

    getStaffSchedule2(idx, name, date) {
      let staffName = name;
      let idxCrmStaff = idx;
      this.consDate = date;
      let flag = false;
      // 상담사 자동완성 체크 부분
      for (var staff of this.counselorList) {
        if (staff.idx === Number(idxCrmStaff)) {
          flag = true;
          break;
        }
      }
      // if (!flag) {
      //   alert("상담사를 다시 확인해주세요1");
      //   return false;
      // }

      if (idxCrmStaff === -1) {
        alert("존재하지 않는 상담사입니다.");
        return false;
      }

      var params = {
        idxCrmStaff: idxCrmStaff,
        consDate: this.consDate,
      };

      this.axios
        .get(`/api/v1/user/staff/schedule`, {
          params: params,
        })
        .then((res) => {
          if (res.data.err === 0) {
            this.isValidCheck = true;
            this.schedule = res.data.schedule;
            this.setConsTime();
            if (res.data.schedule.length > 0) {
              for (var i = 0; i < res.data.schedule.length; i++) {
                // 근무요일 테이블 매핑 (자기 자신거)
                if (res.data.schedule[i].idx !== Number(this.idx)) {
                  this.workTimeList = res.data.schedule[i].consTime.split("/");
                  for (var consTime of this.consTimeList) {
                    // 상담사 일하는 시간 외에는 선택하지 못하도록 추가 20210118
                    if (res.data.workTime.work_time.indexOf(consTime.time) === -1) {
                      consTime.can = false;
                    }
                    if (this.staffTime === consTime.time) {
                      consTime.can = true;
                      consTime.use = true;
                    } else {
                      for (var work of this.workTimeList) {
                        if (work === consTime.time) {
                          consTime.can = false;
                          consTime.use = false;
                        }
                      }
                    }
                  }
                }
              }
            } else {
              if (res.data.workTime !== null && res.data.workTime !== undefined && res.data.workTime !== "null") {
                for (var consTime of this.consTimeList) {
                  if (res.data.workTime.work_time.indexOf(consTime.time) === -1) {
                    consTime.can = false;
                  }
                  if (this.staffTime === consTime.time) {
                    consTime.can = true;
                    consTime.use = true;
                  }
                }
              } else {
                for (var consTime of this.consTimeList) {
                  consTime.can = false;
                }
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.response.data.path === "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        });
    },

    // 센터 자동완성 입력 감지
    watchEvent(e, type) {
      if (type === "counselor") {
        this.autoSearchCounselor = e.target.value;
      } else if (type === "company") {
        this.autoSearchCompany = e.target.value;
      } else if (type === "client") {
        this.autoSearchClient = e.target.value;
      } else {
        this.autoSearchPerson = e.target.value;
      }
    },

    // 자동완성 끄기
    removeValue() {
      if (document.querySelector(".r").classList.contains("key")) {
        document.querySelector(".r").classList.remove("key");
        document.querySelector(".r li.sel").classList.remove("sel");
      }
      if (document.querySelector(".c").classList.contains("key")) {
        document.querySelector(".c").classList.remove("key");
        document.querySelector(".c li.sel").classList.remove("sel");
      }
    },

    // 자동완성 선택한 경우 센터명 변경
    changeValue(str, type, idx, idxPerson, centerIdx, centerName) {
      if (type === "counselor") {
        this.isAutoCounselor = false;
        this.staffName = str;
        this.idxCrmStaff = idx;

        this.getStaffSchedule();
      } else if (type === "company") {
        this.isAutoCompany = false;
        this.companyName = str;
        this.idxCrmCompany = idx;
      } else if (type === "client") {
        this.isAutoClient = false;
        this.name = str;
        this.idxCrmClient = idx;
        this.getPersonData(idxPerson);
        this.getClientData(idx);
      } else {
        this.isAutoPerson = false;
        this.name = str;
        this.idxCrmClient = idx;
        this.getPersonData(idxPerson);
      }
    },

    // 자동완성 켜기 및 선택
    selectValue(keycode, str, idx, idxPerson, centerIdx, centerName) {
      if (this.isAutoCounselor) {
        const hasClass = document.querySelector(".r").classList.contains("key");
        if (keycode === "down") {
          if (!hasClass) {
            const thisEl = document.querySelectorAll(".r li")[0];
            document.querySelector(".r").classList.add("key");
            thisEl.classList.add("sel");
            thisEl.focus();
          } else {
            const lastEl = document.querySelector(".r li:last-child");
            const thisEl = document.querySelector(".r li.sel");
            const nextEl = thisEl.nextElementSibling;
            if (!lastEl.classList.contains("sel")) {
              thisEl.classList.remove("sel");
              nextEl.classList.add("sel");
              nextEl.focus();
            }
          }
        }

        if (keycode === "up" && hasClass) {
          const firstEl = document.querySelectorAll(".r li")[0];
          const thisEl = document.querySelector(".r li.sel");
          const prevEl = thisEl.previousElementSibling;
          if (!firstEl.classList.contains("sel")) {
            thisEl.classList.remove("sel");
            prevEl.classList.add("sel");
            prevEl.focus();
          } else {
            document.querySelector(".s").focus();
          }
        }

        if (keycode === "enter" && hasClass) {
          this.changeValue(str, "counselor", idx, idxPerson, centerIdx, centerName);
        }
      }

      if (this.isAutoClient) {
        const hasClass = document.querySelector(".c").classList.contains("key");
        if (keycode === "down") {
          if (!hasClass) {
            const thisEl = document.querySelectorAll(".c li")[0];
            document.querySelector(".c").classList.add("key");
            thisEl.classList.add("sel");
            thisEl.focus();
          } else {
            const lastEl = document.querySelector(".c li:last-child");
            const thisEl = document.querySelector(".c li.sel");
            const nextEl = thisEl.nextElementSibling;
            if (!lastEl.classList.contains("sel")) {
              thisEl.classList.remove("sel");
              nextEl.classList.add("sel");
              nextEl.focus();
            }
          }
        }

        if (keycode === "up" && hasClass) {
          const firstEl = document.querySelectorAll(".c li")[0];
          const thisEl = document.querySelector(".c li.sel");
          const prevEl = thisEl.previousElementSibling;
          if (!firstEl.classList.contains("sel")) {
            thisEl.classList.remove("sel");
            prevEl.classList.add("sel");
            prevEl.focus();
          } else {
            document.querySelector(".s").focus();
          }
        }

        if (keycode === "enter" && hasClass) {
          this.changeValue(str, "client", idx, idxPerson);
        }
      }

      if (this.isAutoPerson) {
        const hasClass = document.querySelector(".c").classList.contains("key");
        if (keycode === "down") {
          if (!hasClass) {
            const thisEl = document.querySelectorAll(".c li")[0];
            document.querySelector(".c").classList.add("key");
            thisEl.classList.add("sel");
            thisEl.focus();
          } else {
            const lastEl = document.querySelector(".c li:last-child");
            const thisEl = document.querySelector(".c li.sel");
            const nextEl = thisEl.nextElementSibling;
            if (!lastEl.classList.contains("sel")) {
              thisEl.classList.remove("sel");
              nextEl.classList.add("sel");
              nextEl.focus();
            }
          }
        }

        if (keycode === "up" && hasClass) {
          const firstEl = document.querySelectorAll(".c li")[0];
          const thisEl = document.querySelector(".c li.sel");
          const prevEl = thisEl.previousElementSibling;
          if (!firstEl.classList.contains("sel")) {
            thisEl.classList.remove("sel");
            prevEl.classList.add("sel");
            prevEl.focus();
          } else {
            document.querySelector(".s").focus();
          }
        }

        if (keycode === "enter" && hasClass) {
          this.changeValue(str, "client", idx, idxPerson);
        }
      }
    },

    // 자동완성 켜기 및 선택
    selectValueCompany(keycode, str, idx, centerIdx, centerName) {
      if (this.isAutoCounselor) {
        const hasClass = document.querySelector(".r").classList.contains("key");
        if (keycode === "down") {
          if (!hasClass) {
            const thisEl = document.querySelectorAll(".r li")[0];
            document.querySelector(".r").classList.add("key");
            thisEl.classList.add("sel");
            thisEl.focus();
          } else {
            const lastEl = document.querySelector(".r li:last-child");
            const thisEl = document.querySelector(".r li.sel");
            const nextEl = thisEl.nextElementSibling;
            if (!lastEl.classList.contains("sel")) {
              thisEl.classList.remove("sel");
              nextEl.classList.add("sel");
              nextEl.focus();
            }
          }
        }

        if (keycode === "up" && hasClass) {
          const firstEl = document.querySelectorAll(".r li")[0];
          const thisEl = document.querySelector(".r li.sel");
          const prevEl = thisEl.previousElementSibling;
          if (!firstEl.classList.contains("sel")) {
            thisEl.classList.remove("sel");
            prevEl.classList.add("sel");
            prevEl.focus();
          } else {
            document.querySelector(".s").focus();
          }
        }

        if (keycode === "enter" && hasClass) {
          this.changeValue(str, "counselor", idx, centerIdx, centerName);
        }
      }

      if (this.isAutoCompany) {
        const hasClass = document.querySelector(".c").classList.contains("key");
        if (keycode === "down") {
          if (!hasClass) {
            const thisEl = document.querySelectorAll(".c li")[0];
            document.querySelector(".c").classList.add("key");
            thisEl.classList.add("sel");
            thisEl.focus();
          } else {
            const lastEl = document.querySelector(".c li:last-child");
            const thisEl = document.querySelector(".c li.sel");
            const nextEl = thisEl.nextElementSibling;
            if (!lastEl.classList.contains("sel")) {
              thisEl.classList.remove("sel");
              nextEl.classList.add("sel");
              nextEl.focus();
            }
          }
        }

        if (keycode === "up" && hasClass) {
          const firstEl = document.querySelectorAll(".c li")[0];
          const thisEl = document.querySelector(".c li.sel");
          const prevEl = thisEl.previousElementSibling;
          if (!firstEl.classList.contains("sel")) {
            thisEl.classList.remove("sel");
            prevEl.classList.add("sel");
            prevEl.focus();
          } else {
            document.querySelector(".s").focus();
          }
        }

        if (keycode === "enter" && hasClass) {
          this.changeValue(str, "company", idx);
        }
      }

      if (this.isAutoClient) {
        const hasClass = document.querySelector(".c").classList.contains("key");
        if (keycode === "down") {
          if (!hasClass) {
            const thisEl = document.querySelectorAll(".c li")[0];
            document.querySelector(".c").classList.add("key");
            thisEl.classList.add("sel");
            thisEl.focus();
          } else {
            const lastEl = document.querySelector(".c li:last-child");
            const thisEl = document.querySelector(".c li.sel");
            const nextEl = thisEl.nextElementSibling;
            if (!lastEl.classList.contains("sel")) {
              thisEl.classList.remove("sel");
              nextEl.classList.add("sel");
              nextEl.focus();
            }
          }
        }

        if (keycode === "up" && hasClass) {
          const firstEl = document.querySelectorAll(".c li")[0];
          const thisEl = document.querySelector(".c li.sel");
          const prevEl = thisEl.previousElementSibling;
          if (!firstEl.classList.contains("sel")) {
            thisEl.classList.remove("sel");
            prevEl.classList.add("sel");
            prevEl.focus();
          } else {
            document.querySelector(".s").focus();
          }
        }

        if (keycode === "enter" && hasClass) {
          this.changeValue(str, "client", idx);
        }
      }
    },

    // 상담시간 array 구조 생성
    setConsTime() {
      this.consTimeList = [];
      let time = 9;
      for (var i = 0; i < 29; i++) {
        if (time < 10) {
          if (i % 2 == 0) {
            this.consTimeList.push({
              time: "0" + time + ":00",
              use: false,
              can: true,
            });
          } else {
            this.consTimeList.push({
              time: "0" + time + ":30",
              use: false,
              can: true,
            });
            time += 1;
          }
        } else {
          if (i % 2 == 0) {
            this.consTimeList.push({
              time: time + ":00",
              use: false,
              can: true,
            });
          } else {
            this.consTimeList.push({
              time: time + ":30",
              use: false,
              can: true,
            });
            time += 1;
          }
        }
      }
      this.consTimeList.splice(14, 0, {
        time: "-",
        use: false,
        can: false,
      });
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },

    postPersonInfo() {
      if (!EmptyCheck(this.name, "고객명을")) {
        return false;
      }

      if (!this.gender) {
        alert("성별을 선택해주세요");
        return false;
      }

      if (!this.ageRange) {
        alert("연령을 선택해주세요");
        return false;
      }

      // 핸드폰 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
      if (this.phone2 !== "" && this.phone3 !== "") {
        var phone = this.phone1 + "-" + this.phone2 + "-" + this.phone3;
      } else {
        var phone = "";
      }

      /* 신규고객 AND 중복확인 하지 않는 경우 */
      if (this.customerType === "new") {
        if (!this.isPhoneVaild) {
          alert("휴대폰 번호 중복 확인을 해주세요.");
          return false;
        }
      }

      if (phone === "") {
        alert("핸드폰번호를 입력해주세요");
        return false;
      }

      if (!EmptyCheck(this.reservationStatus, "예약현황을")) {
        return false;
      }

      // 근무요일 테이블 파라미터형태로 변경
      let consTimeList = [];
      let copyConsTimeList = JSON.parse(JSON.stringify(this.consTimeList));
      for (var item of copyConsTimeList) {
        if (item.use) {
          delete item["use"];
          consTimeList.push(item);
        }
      }

      this.consTime = "";
      consTimeList.reverse();
      for (var time of consTimeList) {
        this.consTime = time.time + "/" + this.consTime;
      }

      if (!EmptyCheck(this.consTime, "상담시간을")) {
        return false;
      }

      if (this.customerType === "new" || this.customerType === "oldNew") {
        if (!EmptyCheck(String(this.idxCrmCenter), "센터를")) {
          return false;
        }
      }

      if (!EmptyCheck(this.clientStatus, "진행상황을")) {
        return false;
      }

      if (!EmptyCheck(this.idxCrmMetaContact.toString(), "상담 형태를")) {
        return false;
      }
      // 1111
      let flag = false;

      // 고객 자동완성 체크 부분
      if (!EmptyCheck(this.name, "고객을")) {
        return false;
      } else {
        for (var person of this.personList) {
          if (person.name === this.name && person.idx === Number(this.idxCrmPerson)) {
            flag = true;
            break;
          }
        }
      }

      if (!this.gender) {
        alert("성별 선택해주세요");
        return false;
      }

      if (this.customerType !== "new") {
        if (!flag) {
          alert("고객명을 다시 확인해주세요");
          return false;
        }

        if (this.idxCrmPerson === -1) {
          alert("존재하지 않는 고객입니다.");
          return false;
        }
      }

      // 핸드폰 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
      if (this.phone2 !== "" && this.phone3 !== "") {
        var phone = this.phone1 + "-" + this.phone2 + "-" + this.phone3;
      } else {
        var phone = "";
      }

      if (phone === "") {
        alert("휴대폰 번호를 입력해주세요.");
        return false;
      }
      // 3333

      var vo = {
        name: this.name,
        gender: this.gender,
        birth: this.formatDate(this.birth),
        email: this.email,
        idxCrmMetaRoute: this.idxCrmMetaRoute,
        phone: phone,
        ageRange: this.ageRange,
        idxCrmMetaContact: this.idxCrmMetaContact,
        idxCrmCompany: this.idxCrmCompany,
        idxCrmCenter: this.idxCrmCenter,
        idxCrmStaff: this.idxCrmStaff,
        etcPrice: this.etcPrice,
        idxCrmMetaType: this.idxCrmMetaType,
        consCount: this.consCount,
        clientStatus: this.clientStatus,
        eapYn: this.eapYn,
        idxCrmPerson: this.idxCrmPerson,
        idxCrmMetaProduct: this.idxCrmMetaProduct,
        consTime: this.consTime,
        consDate: this.formatDate(this.consDate),
        idxCrmClient: this.idxCrmClient,
        reservationStatus: this.reservationStatus,
        memo: this.memo,
      };

      if (this.staffName === "") {
        vo.idxCrmStaff = null;
      }

      if (this.btnDisabled) {
        alert("저장중입니다. 잠시만 기다려주세요.");
        return false;
      }

      this.btnDisabled = true;
      this.axios
        .post("/api/v1/client/reservation", vo)
        .then((res) => {
          if (res.data.err === 0) {
            //this.$router.push('/origin/person_manage')
            this.idxCrmPerson = res.data.idxCrmPerson;
            //등록 이후 차트를 등록하므로 호출되도록 처리
            this.btnDisabled = false;
            this.idxCrmClient = res.data.idxCrmClient;
            alert("등록되었습니다.");
            this.changeModalStatus();
          } else {
            alert(res.data.result);
          }
        })
        .catch((err) => {
          console.error("error : ", err);
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else if (err !== 0) {
            alert("관리자에게 문의하세요");
          }
        })
        .finally(() => {
          this.btnDisabled = false;
        });
    },

    // postPersonInfo() {
    //       if (!EmptyCheck(this.name, "고객명을")) {
    //         return false;
    //       }

    //       if (!this.gender) {
    //         alert("성별을 선택해주세요");
    //         return false;
    //       }

    //       if (!this.ageRange) {
    //         alert("연령선택 선택해주세요");
    //         return false;
    //       }

    //       // 핸드폰 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
    //       if (this.phone2 !== "" && this.phone3 !== "") {
    //         var phone = this.phone1 + "-" + this.phone2 + "-" + this.phone3;
    //       } else {
    //         var phone = "";
    //       }

    //       /* 신규고객 AND 중복확인 하지 않는 경우 */
    //       if (this.customerType === "new") {
    //         if (!this.isPhoneVaild) {
    //           alert("휴대폰 번호 중복 확인을 해주세요.");
    //           return false;
    //         }
    //       }

    //       if (phone === "") {
    //         alert("핸드폰번호를 입력해주세요");
    //         return false;
    //       }

    //       if (!EmptyCheck(this.reservationStatus, "예약현황을")) {
    //         return false;
    //       }

    //       // 근무요일 테이블 파라미터형태로 변경
    //       let consTimeList = [];
    //       let copyConsTimeList = JSON.parse(JSON.stringify(this.consTimeList));
    //       for (var item of copyConsTimeList) {
    //         if (item.use) {
    //           delete item["use"];
    //           consTimeList.push(item);
    //         }
    //       }

    //       this.consTime = "";
    //       consTimeList.reverse();
    //       for (var time of consTimeList) {
    //         this.consTime = time.time + "/" + this.consTime;
    //       }

    //       if (!EmptyCheck(this.consTime, "상담시간을")) {
    //         return false;
    //       }

    //       if (this.customerType === "new" || this.customerType === "oldNew") {
    //         if (!EmptyCheck(String(this.idxCrmCenter), "센터를")) {
    //           return false;
    //         }
    //       }

    //       if (!EmptyCheck(this.clientStatus, "진행상황을")) {
    //         return false;
    //       }

    //       if (!EmptyCheck(this.idxCrmMetaContact.toString(), "상담 형태를")) {
    //         return false;
    //       }

    //       var vo = {
    //         name: this.name,
    //         gender: this.gender,
    //         birth: this.formatDate(this.birth),
    //         email: this.email,
    //         idxCrmMetaRoute: this.idxCrmMetaRoute,
    //         phone: phone,
    //         ageRange: this.ageRange,
    //         idxCrmMetaContact: this.idxCrmMetaContact,
    //       };

    //       if (this.btnDisabled) {
    //         alert("저장중입니다. 잠시만 기다려주세요.");
    //         return false;
    //       }
    //       this.btnDisabled = true;
    //       this.axios
    //         .post("/api/v1/client/person", vo)
    //         .then((res) => {
    //           if (res.data.err === 0) {
    //             //this.$router.push('/origin/person_manage')
    //             this.idxCrmPerson = res.data.idxCrmPerson;
    //             //등록 이후 차트를 등록하므로 호출되도록 처리
    //             this.btnDisabled = false;
    //             this.postClientInfo();
    //           } else {
    //             alert(res.data.result);
    //           }
    //         })
    //         .catch((err) => {
    //           console.error("error : ", err);
    //           if (err.response.data.path == "/login/msg") {
    //             alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
    //             this.$router.push("/login");
    //           } else {
    //             alert("관리자에게 문의하세요");
    //           }
    //         })
    //         .finally(() => {
    //           this.btnDisabled = false;
    //         });
    //     },
    // 유입경로, 상담구분, 상담유형 리스트
    getMetaList() {
      this.axios
        .get("/api/v1/client/meta")
        .then((res) => {
          if (res.data.err === 0) {
            this.typeList = res.data.typeList;
            this.routeList = res.data.routeList;
            this.contactList = res.data.contactList;
          }
        })
        .catch((err) => {
          console.error(err.response);
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        });
    },

    checkPhoneVaild() {
      /**
       * 1.중복일경우 alert => 사용중인 휴대폰 번호 입니다. 다른번호를 입력해주세요
       * 2.숫자가 아닌 값이 있을 시 alert => 사용할 수 없는 휴대폰 번호 입니다.(O)
       * 3.중복이 없을 경우 alert => 사용가능한 번호입니다.
       * 4.입력 x alert => 휴대폰 번호를 입력하세요(O)
       */
      if (!this.phone1 || !this.phone2 || !this.phone3) {
        alert("휴대폰 번호를 입력하세요");
        return false;
      }

      if (!this.numberRegExp.test(this.phone1) || !this.numberRegExp.test(this.phone2) || !this.numberRegExp.test(this.phone3)) {
        alert("사용할 수 없는 휴대폰 번호 입니다. 숫자만 입력해주세요.");
        /**
         * 숫자가 아닌 경우 초기화
         */
        if (!this.numberRegExp.test(this.phone1)) {
          this.phone1 = "";
        }
        if (!this.numberRegExp.test(this.phone2)) {
          this.phone2 = "";
        }
        if (!this.numberRegExp.test(this.phone3)) {
          this.phone3 = "";
        }
        return false;
      }

      this.axios
        .get(`/api/v1/client/person/dup_phone`, {
          params: {
            phone: `${this.phone1}-${this.phone2}-${this.phone3}`,
          },
        })
        .then((response) => {
          const { err, result } = response.data;

          if (err === 100) {
            alert(result);
          } else {
            alert(result);
            this.isPhoneVaild = true;
          }
        })
        .catch((err) => {
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          }
        });
    },
    /**
     * 상담형태 목록
     */
    getConTypeMeta() {
      this.axios
        .get(`/api/v1/cons/report/type_meta`, {})
        .then((response) => {
          this.conTypeList = response.data.data;
        })
        .catch((err) => {
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        });
    },

    /**
     * @description:
     */
    changeModalStatus() {
      this.$emit("modalFromChild");
    },

    /**
     * @description : 구분 리스트
     */
    getGubunList() {
      this.axios
        .get("/api/v1/client/gubun")
        .then((res) => {
          if (res.data.err === 0) {
            this.gubunList = res.data.gubunList;
            this.productList = res.data.productList;
          } else {
            this.gubunList = [];
          }
        })
        .catch((err) => {
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        });
    },

    /**
     * @description : 구분에 따른 상품 리스트
     */
    getProductList() {
      this.idxCrmMetaProduct = -1;
      let params = {
        value: this.gubun || "",
      };

      this.axios
        .get("/api/v1/client/gubun", {
          params: params,
        })
        .then((res) => {
          if (res.data.err === 0) {
            this.productList = res.data.productList;
          } else {
            this.productList = [];
          }
        })
        .catch((err) => {
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        });
    },

    /***
     * @description : 센터 리스트 추출
     */
    getCenterListAll() {
      this.axios
        .get("/api/v1/center/all", {})
        .then((res) => {
          if (res.data.err === 0) {
            this.centerList = res.data.centerList;
          } else {
            this.centerList = [];
          }
        })
        .catch((err) => {
          console.error(err.response);
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        });
    },
    getStaffList() {
      var params = {
        idx: this.idxCrmCenter,
      };
      this.axios
        .get(`/api/v1/center/staff/${this.idxCrmCenter}`, {
          params: params,
        })
        .then((res) => {
          if (res.data.err === 0) {
            this.staffName = "";
            this.counselorList = res.data.staffList;
          }
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &.modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  &-window {
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
  }

  &-content {
    padding: 10px 20px;
    background: #fff;
  }

  &-footer {
    // background: #ccc;
    padding: 10px;
    text-align: right;
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.4s;

  .modal-window {
    transition: opacity 0.4s, transform 0.4s;
  }
}

.modal-leave-active {
  transition: opacity 0.4s ease 0.2s;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;

  .modal-window {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.app {
  display: table-cell;
  vertical-align: middle;
  text-align: center;

  .search {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;

    .s {
      float: left;
      margin-left: 15px;
      width: 255px;
      height: 30px;
      object-fit: contain;
      border-radius: 3px;
      border: solid 1px #cccccc;
      background-color: #ffffff;
      padding-left: 15px;

      &::placeholder {
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 4.29;
        letter-spacing: normal;
        text-align: left;
        color: #cccccc;
      }
    }

    .c {
      display: none;
      position: absolute;
      left: 0;
      top: 28px;
      width: 100%;
      height: 156px;
      overflow-y: auto;
      list-style: none;
      padding-left: 0px;

      &.show {
        display: block;
        z-index: 10;
      }

      li {
        margin-top: -1px;
        padding: 0 10px;
        height: 40px;
        background-color: #fff;
        box-sizing: border-box;
        border: 1px solid #dcdcdc;
        outline: none;
        font-size: 16px;
        line-height: 40px;
        text-align: left;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover,
        &.sel {
          background-color: darken(#fff, 5%);
        }

        &:last-child {
          border-radius: 0px 0px 4px 4px;
        }
      }
    }

    .r {
      display: none;
      position: absolute;
      left: 0;
      top: 28px;
      width: 100%;
      height: 156px;
      overflow-y: auto;
      list-style: none;
      padding-left: 20px;

      &.show {
        display: block;
        z-index: 10;
      }

      li {
        margin-top: -1px;
        padding: 0 20px;
        width: 91%;
        height: 40px;
        background-color: #fff;
        box-sizing: border-box;
        border: 1px solid #dcdcdc;
        outline: none;
        font-size: 16px;
        line-height: 40px;
        cursor: pointer;

        &:hover,
        &.sel {
          background-color: darken(#fff, 5%);
        }

        &:last-child {
          border-radius: 0px 0px 4px 4px;
        }
      }
    }

    p {
      padding: 10px 0;
      text-align: right;
      font-size: 12px;
    }
  }
}

.point {
  font-family: gulim;
  font-size: 9pt;
  color: #faf7e7;
}

.textbox {
  font-family: gulim;
  font-size: 9pt;
  color: #736257;
  height: 20px;
}

.msg_wrap {
  width: 230px;
}

.msg_deco_wrap {
  border-radius: 13px 13px 0 0;
  background: #efefef;
  padding: 12px 0 0 0;
}

.msg_deco {
  width: 40px;
  height: 2px;
  background: #ababab;
  border-radius: 20px;
  margin: 0 auto;
}

.msg_box {
  background: #efefef;
  padding: 8px 20px 10px 20px;
  display: block;
  height: 200px;
}

.msg_box textarea {
  width: 100%;
  height: 100%;
}

.msg_send {
  background: #efefef;
  padding: 0 20px 20px 20px;
}

.msg_footer {
  background: #efefef;
  border-radius: 0 0 13px 13px;
  padding: 0 20px 20px 20px;
  text-align: center;
}
</style>
